import React from 'react';
import ReactDOM from 'react-dom';
import Routes from './routes'
import './index.css';
import {AppContainer} from 'react-hot-loader'
import StateProvider from "./containers/StateProvider";
import * as serviceWorker from './serviceWorker';
import ReactGA from 'react-ga';
ReactGA.initialize('UA-61658764-2');
ReactGA.pageview(window.location.pathname + window.location.search);

const renderApp = (Component) => {
  ReactDOM.render(
    <AppContainer>
      <Component />
    </AppContainer>,
    document.getElementById('root')
  );
};

const App = (props) => {
  return (
    <StateProvider {...props}>
      <Routes />
    </StateProvider>
  )
}

renderApp(App);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
