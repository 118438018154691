import algoliasearch from "algoliasearch";
import Cookies from "js-cookie";

const isNotEmpty = (string) => {
  return (string !== "" && string !== "null" && string !== "undefined" && string !== undefined && string !== null)
}

function getShuffledArr (array){
    for (let i = array.length - 1; i > 0; i--) {
        const rand = Math.floor(Math.random() * (i + 1));
        [array[i], array[rand]] = [array[rand], array[i]]
    }
}

const forgotPassword = (state) => {
  return (async () => {
    var myHeaders = new Headers({
      "Content-Type": "application/json"
    });

    var myInit = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({
        Email: state.state.email
      })
    };

    var myRequest = new Request(`${process.env.REACT_APP_API_HOST}/api/users/forgot_password`, myInit);
    const rawResponse = await fetch(myRequest)
    const content = await rawResponse.json();

    return content;
  })();
}

const forgotPasswordCheck = (state) => {
  let user_id = state.state.user_id || window.location.pathname.split("/")[2];
  let hash_used = state.state.hash_used || window.location.pathname.split("/")[4];

  return (async () => {
    var myHeaders = new Headers({
      "Content-Type": "application/json"
    });

    var myInit = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({
        Password: state.state.password,
        Hash: hash_used,
        Id: parseInt(user_id)
      })
    };

    var myRequest = new Request(`${process.env.REACT_APP_API_HOST}/api/users_check/${user_id}/forgot_password_check`, myInit);
    const rawResponse = await fetch(myRequest)
    const content = await rawResponse.json();

    return content;
  })();
}

const updateUser = (userPassed, state) => {
  const user = userPassed || state.state.user || state.props.user;

  return (async () => {
    const token = Cookies.get("token") || localStorage.getItem("auth_token");
    var myHeaders = new Headers({
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`
    });

    var myInit = {
      method: 'PUT',
      headers: myHeaders,
      body: JSON.stringify({
        WantsEmail: user.WantsEmail,
        Email: user.Email,
        IdealSalary: user.IdealSalary,
        YearsOfTotalExperience: user.YearsOfTotalExperience,
        WorkedRemotelyBefore: user.WorkedRemotelyBefore,
        LinkedInUrl: user.LinkedInUrl,
        GithubUrl: user.GithubUrl,
        PortfolioUrl: user.PortfolioUrl,
        TypeOfJobSearcherName: user.TypeOfJobSearcherName || "Software",
        Skills: user.SkillsToSet,
        WorkedRemotelyBefore: false
      })
    };
    var myRequest = new Request(`${process.env.REACT_APP_API_HOST}/api/users/${user.ID}/update`, myInit);

    const rawResponse = await fetch(myRequest)
    const content = await rawResponse.json();
    state.setState({user: content})

    return content;
  })();
}

const userRequest = function(state) {
  const auth_token = Cookies.get("token") || localStorage.getItem("auth_token");
  if (isNotEmpty(auth_token)) {
    var myHeaders = new Headers({
      "Content-Type": "application/json",
      "Authorization": `Bearer ${auth_token}`
    });

    var myInit = {
      method: 'GET',
      headers: myHeaders,
      cache: 'default'
    };
    var myRequest = new Request(`${process.env.REACT_APP_API_HOST}/api/users/me?token=${auth_token}`, myInit);

    let user;
    return fetch(myRequest)
    .then((response) => response.json())
    .then((json) => {
      user = json;
      console.log('set user', user)

      if (json.user && isNotEmpty(json.user.email)) {
        if (state !== undefined) {
          state.setState({
            user: json,
            loggedIn: true
          });

          Cookies.set("token", json.user.token);
          Cookies.set("userData", json.user);
        }
      } else {
        if (state !== undefined) {
          state.setState({
            user: null,
            loggedIn: false
          });
        }

        user = null;
      }

      console.log('user', user)
      return user;
    });
  } else {
    return new Promise((resolve, reject) => {
      return resolve(null)
    })
  }

}

const updateCurrentUser = function(state, user) {
  state.setState({
    user: user
  });
}

const loginCall = function(state) {
  const form = {email: state.state.email, password: state.state.password};
  const searchParams = Object.keys(form).map((key) => {
    return encodeURIComponent(key) + '=' + encodeURIComponent(form[key]);
  }).join('&');

  return (async () => {
    const rawResponse = await fetch(`${process.env.REACT_APP_API_HOST}/api/sessions/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
      },
      body: searchParams
    })
    const content = await rawResponse.json();

    if (content.user) {
      localStorage.setItem('auth_token', content.user.token)
      Cookies.set("token", content.user.token);
      Cookies.set("userData", content.user);
      state.getUserRequest();
    } else {
      const error = content.Message;
      state.setState({
        errorMessage: error
      });
      return error;
    }
  })();
}

const signupCall = function(state) {
  if (state.state.password_confirmation != state.state.password) {
    return;
  }
  // IsJobSeeker: state.props.user.IsJobSeeker, WorkedRemotelyBefore: state.props.user.WorkedRemotelyBefore
  const form = {email: state.state.email, password: state.state.password, full_name: state.state.full_name, IsJobSeeker: state.state.IsJobSeeker, WorkedRemotelyBefore: state.state.WorkedRemotelyBefore};
  const searchParams = Object.keys(form).map((key) => {
    return encodeURIComponent(key) + '=' + encodeURIComponent(form[key]);
  }).join('&');

  return (async () => {
    const rawResponse = await fetch(`${process.env.REACT_APP_API_HOST}/api/users/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
      },
      body: searchParams
    })
    const content = await rawResponse.json();

    if (content.user) {
      localStorage.setItem('auth_token', content.user.token)
      Cookies.set("token", content.user.token);
      Cookies.set("userData", content.user);
      state.getUserRequest();
    } else {
      const error = content.Message;
      state.setState({
        errorMessage: error
      });
      return error;
    }
  })();
}

const remove_style = (all) => {
  var i = all.length;
  var j, is_hidden;

  // Presentational attributes.
  var attr = [
    'align',
    'background',
    'bgcolor',
    'border',
    'cellpadding',
    'cellspacing',
    'color',
    'face',
    'height',
    'hspace',
    'marginheight',
    'marginwidth',
    'noshade',
    'nowrap',
    'valign',
    'vspace',
    'width',
    'vlink',
    'alink',
    'text',
    'link',
    'frame',
    'frameborder',
    'clear',
    'scrolling',
    'style'
  ];

  var attr_len = attr.length;

  while (i--) {
    is_hidden = (all[i].style.display === 'none');

    j = attr_len;

    while (j--) {
      all[i].removeAttribute(attr[j]);
      // const replaced_string = all[i].innerHtml.replace(/<\/?[^>]+(>|$)/g, "").replace("&nbsp;", "");;
      // all[i].innerHtml = replaced_string;
    }

    // Re-hide display:none elements,
    // so they can be toggled via JS.
    if (is_hidden) {
      all[i].style.display = 'none';
      is_hidden = false;
    }
  }
}

const Number = {
  /**
   * Formats a given number with thousand separators
   * so 1000 becomes 1,000
   *
   * @param number the number to format
   * @returns {string} number as string formatted with , as a thousand separator.
   */
  formatNumber(number) {
    if (number === undefined || number == null) return number;
    const parts = number.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  }
};


export { Number, userRequest, signupCall, loginCall, isNotEmpty, updateUser, remove_style, forgotPassword, forgotPasswordCheck };
