import React from 'react';
import {Link} from 'react-router-dom';
import Moment from 'react-moment';
import 'moment-timezone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock, faStar } from '@fortawesome/free-solid-svg-icons'
import { isNotEmpty, Number } from "../../util/user";

export default class PropertyAlgolia extends React.Component {
  constructor(props) {
    super(props);
  }

  addDefaultSrc(ev) {
    ev.target.src = "";
  }

  render() {
    const result = this.props.result;
    const id = result.objectID.replace("property-", "")
    const listingKey = result.ListingId
    // should be slug
    let image = result.Image
    image = image.replace("-dev", "-prod")
    // https://hamilton-homes-prod.s3.amazonaws.com/property_images/OzfJ7VtBhtWIoolnO7Vf5TNW1pL_x0eUuv6i-7V-Y1g=jpeg/original?v=63751120383
    image = image.replace("https://hamilton-homes-prod.s3.amazonaws.com", "https://dp92u1r0kzfby.cloudfront.net")

    return (
      <>
          <div className="c-residence__content">
            <div className="c-residence__content-inner u-bg-white">
              <h2>{result.StreetAddress}</h2>
              <h3>{result.City}, Ontario {result.PostalCode}</h3>
              <h3 className="price">${Number.formatNumber(result.ListPrice)}</h3>

              <a className="c-btn__text c-btn__text--top" href={"/listings/"+listingKey}>
                Learn more
              </a>
            </div>
          </div>

          <figure className="c-residence__photo o-cover o-cover--rel">
            <img onError={this.addDefaultSrc} src={image} />
          </figure>
      </>
    )
  }
}
