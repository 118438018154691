import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch
} from 'react-router-dom';
import FullListing from './listings/FullListing';
import Listings from './listings/Listings';

class ListingLayout extends React.Component {
  constructor(props) {
    super(props)
    // this.props.user = this.props.user;
  }

  render() {
    return (
      <Switch user={this.props.user}>
        <Route exact path="/listings/" component={() => <Listings />} />
        <Route path="/listings/:id" component={() => <FullListing />} />
      </Switch>
    )
  }
}

export default ListingLayout;
