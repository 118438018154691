import React from 'react';
import ReactPixel from 'react-facebook-pixel';

import {
  Router,
  Route,
  Switch
} from 'react-router-dom';
import { useEffect, useContext } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import App from './App';
// import MessengerCustomerChat from 'react-messenger-customer-chat';

// import About from './components/About';
import Browse from './components/Browse';
import Contact from './Contact';
import About from './About';
import Experiences from './Experiences';
// import Evaluation from './components/Evaluation';
// import Sell from './components/Sell';
// import Browse from './components/Browse';
// import Meta from './components/Meta';
// import Listings from './components/listings/Listings';
import ListingLayout from "./components/ListingLayout";
import Terms from './Terms';
// import CommunityLayout from "./components/CommunityLayout";
// import BlogLayout from "./components/BlogLayout";
// import AdminLayout from "./components/AdminLayout";

// import Account from './components/Account';
// import Navigation from './components/Navigation';
// import Footer from './components/Footer';
// import GeneralBrowse from './components/GeneralBrowse';
import NoMatch from './components/NoMatch';
// import SignUp from "./base/sign-up.jsx";
// import Logout from "./base/Logout.jsx";
import ReactGA from 'react-ga';

// import 'normalize.css';
// import "./app.sass";
// import { userRequest, isNotEmpty } from "./util/user";
import createHistory from 'history/createBrowserHistory'

// import Helmet from "react-helmet";
// import renderIf from "render-if";
// import { AuthContext } from "./util/context";

const history = createHistory()
history.listen((location, action) => {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
});

const advancedMatching = { }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/pixel-with-ads/conversion-tracking#advanced_match
const options = {
    autoConfig: true, 	// set pixel's autoConfig
    debug: false, 		// enable logs
};
ReactPixel.init('244901703240113', advancedMatching, options);

// Scroll restoration based on https://reacttraining.com/react-router/web/guides/scroll-restoration.
export var ScrollToTop = withRouter(
  class ScrollToTopWithoutRouter extends React.Component {
    componentDidUpdate(prevProps) {
      if (this.props.location !== prevProps.location) {
        window.scrollTo(0, 0)
      }

      ReactPixel.pageView();
    }

    render() {
      return null;
    }
  }
);

const Routes = (props) => {
  // const { token, userData, setAuth } = useContext(AuthContext);
  // const auth_token = localStorage.getItem("auth_token");
  //
  // useEffect(() => {
  //   if (isNotEmpty(auth_token) || isNotEmpty(token)) {
  //     userRequest().then((userData) => {
  //       let to_use = auth_token || token
  //       setAuth({userData, token: to_use})
  //     });
  //   }
  // }, []);
  //
  // // note: need to test for forgot password, account calls?
  // const updateUserForRoutes = userData => {
  //   const token = localStorage.getItem("auth_token");
  //   setAuth({token, userDa);
  // };

  return (
    <Router history={history}>
      <React.Fragment>
        <ScrollToTop />

        <>
          <Switch>
            <Route exact path="/" render={() => <App />}/>
            <Route path="/listings/" render={() => <ListingLayout />}/>
            <Route path="/experiences/" render={() => <Experiences />}/>
            <Route path="/contact/" render={() => <Contact />}/>
            <Route path="/about/" render={() => <About />}/>
            <Route path="/browse/" render={() => <Browse />}/>
            <Route path="/terms" render={() => <Terms />}/>
            <Route component={NoMatch} />
          </Switch>
        </>
      </React.Fragment>
    </Router>
  )
};

export default Routes;
