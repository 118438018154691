import React from 'react';


export default class DisplayFilters extends React.Component {
  constructor(props) {
    super(props);
    let filters = this.props.filters || [];

    this.state = {
      selected: filters[0].title
    }

    this.handleRadioChange = this.handleRadioChange.bind(this);
  }

  handleRadioChange(fieldSelected, e) {
    let field = this.props.title
    let map = this.props.type
    console.log('fieldSelected', fieldSelected)
    console.log('field', field)
    console.log('map', map)
    console.log('e', e)
    this.setState({
      selected: e.target.value
    })
    this.props.handleRadioChange(e.target.value, map)
  }

  // <div className="variation">
  //   <label className="checkbox-ctn"><span className="title-label">{item.title}</span>
  //     <input type="checkbox" className="filter-checkbox checkbox" name="item"
  //     onChange={this.handleRadioChange.bind(this, item.title, type)}
  //     checked={item.checked} />
  //     <span className="checkmark"></span>
  //   </label>
  // </div>

  render() {
    let filters = this.props.filters || [];
    let title = this.props.title;
    let type = this.props.type; //"titleSideBarResults"

    return (
      <div className="display-filter">
        <h3><b>{title}:</b></h3>
        <div className="variations">
        <select className="select-opt" id="lang" onChange={this.handleRadioChange.bind(this, type)} value={this.state.selected}>
          {filters.map((item, index) =>
            <option key={title+"-title-"+item.title+index} value={item.title}>{item.title}</option>
          )}
          </select>
        </div>
      </div>
    )
  }
}
