import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { AuthContext, ChatContext } from "../util/context";

const StateProvider = ({
  initialToken,
  initialUserData,
  children,
}) => {
  const [token, setToken] = useState(initialToken || Cookies.get("token"));
  const [userData, setUserData] = useState(initialUserData || JSON.parse(Cookies.get("userData") || "{}"));

  // If the token changed (i.e. the user logged in
  // or out), clear the Apollo store and refresh the
  // websocket connection.
  // useEffect(() => {
  //   if (!token) client.clearStore();
  //   if (socket) refreshSocket(socket);
  // }, [client, socket, token]);

  const setAuth = (data) => {
    console.log('data', data)
    if (data) {
      const { userData, token } = data;
      Cookies.set("token", token);
      Cookies.set("userData", userData);
      localStorage.setItem("auth_token", token)
      setToken(token);
      setUserData(userData);
    } else {
      console.log('removing', new Date())
      Cookies.remove("token");
      Cookies.remove("userData");
      // localStorage.setItem("auth_token", null)
      localStorage.removeItem("auth_token");
      setToken(null);
      setUserData(null);
    }
  };

  return (
    <AuthContext.Provider value={{ token, userData, setAuth }}>
      {children}
    </AuthContext.Provider>
  );
};

export default StateProvider;
