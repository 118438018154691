import React, {Fragment} from 'react';
import ReactDOMServer from 'react-dom/server'
import { remove_style } from "../../util/user";

export default class DescCut extends React.Component {
  constructor(props) {
    super(props);
    let string = this.props.description !== undefined ? this.props.description : "Hamilton Real Estate News";

    if (string !== null && string !== undefined) {
      if (this.props.truncate) {
        const maxLength = 1000;
        string = string.substr(0, maxLength - 1) + (string.length > maxLength ? '...' : '');
      }
      string = string.replace("&nbsp;", "");
    } else {
      string = "<div></div>";
    }

    let html = ReactDOMServer.renderToString(string);
    let frag = document.createRange().createContextualFragment(string);

    this.state = {
      string: string,
      html: html,
      frag: frag
    }
  }

  componentDidMount() {
    const string = `job-full-desc-${this.props.id}`;
    const jobParent = document.getElementById(string);

    jobParent.appendChild(this.state.frag);
    const all = jobParent.getElementsByTagName('*');
    remove_style(all);
  }

  render() {
    const html = this.state.string;
    const string = `job-full-desc-${this.props.id}`;

    return (
      <div className="desc-cut" id={string}>
      </div>
    )
  }
}
