import React from 'react';


export default class BrowseFilterText extends React.Component {
  constructor(props) {
    super(props);
    this.clearFilter = this.clearFilter.bind(this);
  }

  clearFilter() {
    this.props.clearFilter(this.props.type);
  }

  render() {
    let filters = this.props.filters || [];
    let text = filters.filter((result) => result.checked).map((item) => item.title).join(", ");
    text = text.trim().replace(/(^,)|(,$)/g, "");

    return (
      <div className="filter"><em>{this.props.title}:</em>
        <span className="filter-text">{text}</span>
        <span className="clear-filter" onClick={() => this.clearFilter()}>x</span>
      </div>
    )
  }
}
