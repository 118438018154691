import React, { Component } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
// import logo from '../images/logo.svg';

export default class Carousel extends React.Component {
  constructor(props) {
    super(props);
    let items = this.props.items || []
    console.log('items', items)
    let currentItemView = items[0];

    console.log('currentItemView here', currentItemView)

    this.state = {
      items: items,
      currentItemView: currentItemView,
      currentItem: 0,
      delay: (Math.random() * 800)
    }

    this.carouselChange = this.carouselChange.bind(this);
    this.changeCarouselToPoint = this.changeCarouselToPoint.bind(this);
    this.changeCarousel = this.changeCarousel.bind(this);
  }

  addDefaultSrc(ev) {
    // ev.target.src = logo;
  }

  componentDidMount() {
    setInterval(() => {
      this.carouselChange();
    }, (10000 + this.state.delay));
  }

  carouselChange() {
    let itemToShow = this.state.currentItem + 1 >  this.props.items.length ? 0 : this.state.currentItem + 1;
    let newItem =  this.props.items[itemToShow];

    if (newItem === undefined) {
      newItem =  this.props.items[0];
      itemToShow = 0;
    }

    // console.log('changing currentItemView', newItem)

    this.setState({
      currentItem: itemToShow,
      currentItemView: newItem
    })
  }

  changeCarouselToPoint(number) {
    let newItem =  this.props.items[number];

    if (newItem === undefined) {
      newItem =  this.props.items[0];
      number = 0;
    }
    this.setState({
      currentItem: number,
      currentItemView: newItem
    });
  }

  // componentDidUpdate(prevProps, prevState, snapshot) {
  //   const { items } = prevProps.items;
  //   if (items !== prevState.items) {
  //     this.changeCarouselToPoint(0)
  //   }
  // }

  changeCarousel(direction) {
    const currentItem = this.state.currentItemView ? this.state.currentItemView.Image : this.props.items[0] === undefined ? "" : this.props.items[0].Image
    const currentItemIndex =  this.props.items.findIndex((item) => {
      return item.Image === currentItem
    })

    let newIndex = currentItemIndex + 1

    if (direction === "left") {
      newIndex = currentItemIndex - 1
    }

    if (newIndex < 0) {
      let length =  this.props.items.length;
      newIndex = length + newIndex
    }


    let newItem =  this.props.items[newIndex];

    if (newItem === undefined) {
      newItem =  this.props.items[0];
      newIndex = 0;
    }

    this.setState({
      currentItem: newIndex,
      currentItemView: newItem
    });
  }

  render() {
    let classNames = "section carousel split";
    if (this.props.inverted) {
      classNames = `${classNames} reversed-split`;
    }

    let hideArrows = this.props.hideArrows || false;
    console.log(' this.props.items',  this.props.items)
    console.log('this.state.currentItemView', this.state.currentItemView)
    let currentImage = this.state.currentItemView ? this.state.currentItemView.Image : this.props.items[0] === undefined ? "" : this.props.items[0].Image
    currentImage = currentImage.replace("-dev", "-prod")
    console.log('currentImage', currentImage)
    let listing = this.props.listing

    return (
      <div className="o-page__mask | js-page__mask">



      <div className="c-hero  o-gutter | js-smooth-section js-hero">
      <div className="js-into-item intro-right">
          {!hideArrows &&
            <div onClick={() => this.changeCarousel("left")} className="left-arrow arrow">
              <div className="arrow-content">&lt;</div>
            </div>
          }
          {!hideArrows &&
            <div onClick={() => this.changeCarousel("right")} className="right-arrow arrow">
              <div className="arrow-content">&gt;</div>
            </div>
          }
      </div>
      <div id={this.props.id} className={classNames}>
        <div className="content">
          {currentImage &&
            <img onError={this.addDefaultSrc} className="c-hero__bg o-cover o-cover--abs hero-listing" src={currentImage} />
          }
        </div>

        <div className="gallery">
          {this.props.items.map((image, index) =>
            <img src={image.Image.replace("-dev","-prod")} key={"p-image" + index} style={{display:"none"}} alt="" />
          )}
        </div>
      </div>

    <h1
      className="o-h1 c-hero__title | js-transition-line move-down"
    >
      {listing && listing.UnparsedAddress}
    </h1>
    <div className="c-hero__cta">
            <span className="js-intro-item">Scroll down</span>
        </div>

        <div className="c-hero__box c-hero__box--residence | js-hero__box">
        <div className="c-hero__box-inner | js-hero__box-inner">

                    <h3 className="c-hero__file-title o-h6">Bedrooms/<br />Baths:</h3>
            <ul>

                            <li>
                  <a className="c-hero__file">{listing && listing.BedroomsTotal} br</a>
                </li>
                            <li>
                  <a className="c-hero__file">{listing && listing.BathroomsTotal} bth</a>
                </li>

            </ul>

        </div>
      </div>

    </div>
    </div>
    )
  }
}
